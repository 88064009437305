export default function Footer() {
  return (
    <footer>
      <div>
        <div className="logos">
          <a
            href="https://www.cinipravustvar.hr/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={require('./images/cps-logo.png')}
              alt="Čini pravu stvar i brini o okolišu"
            />
          </a>

          <a href="https://www.go2digital.hr/" target="_blank" rel="noreferrer">
            <img src={require('./images/go2digital.png')} alt="Go2Digital" />
          </a>
        </div>
        ©&nbsp;2023&nbsp;A1&nbsp;Hrvatska / Go2Digital
      </div>
      <video src={require('./images/bees.mp4')} muted autoPlay loop />
    </footer>
  );
}
