import { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import queryString from 'query-string';
import ReactModal from 'react-modal';
import 'react-alice-carousel/lib/alice-carousel.css';

const qs = queryString.parse(window.location.search);
const path = window.location.pathname.toLowerCase().trim();

const VOTE =
  qs?.vote === 'true' || +new Date() > Date.parse('2024-10-22T00:00:00.000Z');

const VOTE_DONE =
  qs?.vote_done === 'true' ||
  +new Date() > Date.parse('2024-11-07T22:59:59.000Z') ||
  path !== '/kad-odrastem-zelim-beeti';

//const VOTE_DONE = true;

export interface Gallery {
  galleryId: number;
  name: string;
  items: GalleryItem[];
}

export interface GalleryItem {
  itemId: number;
  title: string;
  image: string;
  thumb: string;
}

let myVotesStorage: number[] = [];
try {
  myVotesStorage = JSON.parse(
    window.localStorage.getItem('gigabeetno-votes') || '[]'
  );
} catch (e) {
  console.error(e);
}

try {
  let legacyVote = parseInt(
    window.localStorage.getItem('gigabeetno-voted') || '0',
    10
  );
  myVotesStorage.push(legacyVote);
} catch (e) {
  console.error(e);
}

export default function GallerySection() {
  let [galleries, setGalleries] = useState<Gallery[] | null>(null);
  let [votes, setVotes] = useState<Map<number, number>>(new Map());
  let [image, setImage] = useState<string | null>(null);
  let [voteInProgress, setVoteInProgress] = useState<boolean>(false);
  let [voteModal, setVoteModal] = useState<number | null>(null);
  let [myVotes, setMyVotes] = useState<number[]>(myVotesStorage);

  let voteEmailRef = useRef<HTMLInputElement>(null);
  const winnersToFilter = [465, 577, 760];

  useEffect(() => {
    fetch('https://www.go2digital.hr/api/gallery/group/gigabeetno3/').then(
      async (resp) => {
        let data = await resp.json();
        setGalleries(data);
      }
    );

    fetch('https://www.go2digital.hr/api/gallery/vote/').then(async (resp) => {
      let data = (await resp.json()) as [number, number][];
      setVotes(new Map(data));
    });
  }, []);

  async function vote(item_id: number, email: string) {
    if (voteInProgress) return;
    setVoteInProgress(true);
    try {
      const rawResponse = await fetch(
        'https://www.go2digital.hr/api/gallery/vote/',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ item_id, email }),
        }
      );
      const content = await rawResponse.json();
      let mv = [...myVotes, item_id];
      localStorage.setItem('gigabeetno-votes', JSON.stringify(mv));
      setMyVotes(mv);
      let newVotes = new Map(votes);
      newVotes.set(item_id, (newVotes.get(item_id) || 0) + 1);
      setVotes(newVotes);
      setVoteModal(null);
    } finally {
      setVoteInProgress(false);
    }
  }

  return (
    <>
      <div className="section-6-title">
        {image && (
          <div className="modal" onClick={() => setImage(null)}>
            <img src={image} alt="" />
          </div>
        )}

        <ReactModal
          isOpen={voteModal != null}
          contentLabel=""
          onRequestClose={() => setVoteModal(null)}
          className="Modal"
          overlayClassName="Overlay"
        >
          <p>
            Prilikom glasovanja potrebno je upisati adresu elektroničke pošte, a
            njime se daje privola za obradu iste adrese elektroničke pošte i s
            koje je moguće samo jednom glasovati za odabrane grupne radove.
            Adrese elektroničke pošte prikupljaju se i obrađuju isključivo u
            svrhu evidentiranja danog glasa za pojedini likovni rad te da će
            nakon završetka Nagradnog natječaja biti nepovratno brisane.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              vote(voteModal || 0, voteEmailRef?.current?.value || '');
            }}
            className="form"
          >
            <input
              type="email"
              name="email"
              ref={voteEmailRef}
              required
              placeholder="E-mail adresa"
            />
            <input type="submit" value="Glasaj" />
          </form>
          <button onClick={() => setVoteModal(null)}>Close Modal</button>
        </ReactModal>
      </div>

      <div className="section-6-wrap">
        {galleries &&
          galleries.map((gallery) => (
            <GalleryGroup
              key={gallery.galleryId}
              votes={votes.get(gallery.items[0].itemId) || 0}
              hasVoted={myVotes}
              gallery={gallery}
              openImage={setImage}
              voteModal={setVoteModal}
            />
          ))}
      </div>
    </>
  );
}

const responsive = {
  0: { items: 1 },
  500: { items: 2 },
  1200: { items: 3 },
};

export function GalleryGroup(props: {
  gallery: Gallery;
  votes: number;
  hasVoted: number[];
  openImage: (img: string) => void;
  voteModal: (id: null | number) => void;
}) {
  let items = props.gallery.items.map((item) => (
    <GalleryItemView
      key={item.itemId}
      gallery={props.gallery}
      item={item}
      openImage={props.openImage}
    />
  ));

  for (let i = items.length; i < 3; i++) {
    // expand to min three items
    items.push(<div className="gallery-item" />);
  }

  function glasDeklinacija(votes: number) {
    if (votes === 0) return 'glasova';
    if (votes === 1) return 'glas';
    if (votes === 2) return 'glasa';
    if (votes === 3) return 'glasa';
    if (votes === 4) return 'glasa';
    return 'glasova';
  }

  let itemId: number | undefined = props.gallery.items[0]?.itemId;
  let winner =
    [367, 216, 380, 465, 577, 760, 836, 1002, 1794].indexOf(
      props.gallery.items[0]?.itemId
    ) !== -1;

  return (
    <div className={'section-6-gallery' + (winner ? ' winner' : '')}>
      <h2 className="section-6-names">{props.gallery.name}</h2>
      {VOTE && (
        <div className="section-6-vote">
          {!props.hasVoted.includes(itemId) && !VOTE_DONE && (
            <div
              className="section-6-vote-btn"
              onClick={() => props.voteModal(props.gallery.items[0]?.itemId)}
            >
              Glasaj
            </div>
          )}
          {props.hasVoted.includes(props.gallery.items[0]?.itemId) && (
            <div className="section-6-vote-btn">Tvoj glas</div>
          )}

          <div className="section-6-vote-count">
            {props.votes} {glasDeklinacija(props.votes)}
          </div>

          {winner && (
            <div className="section-6-vote-winner">
              <img
                src={require('./images/winner.svg').default}
                width="126"
                height="102"
                alt="Pobjednik"
              />
            </div>
          )}
        </div>
      )}
      <div className="section-6-creative">
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="alternate"
          disableDotsControls
          renderPrevButton={({ isDisabled }) => {
            if (isDisabled) return null;
            return <div className="left-btn" />;
          }}
          renderNextButton={({ isDisabled }) => {
            if (isDisabled) return null;
            return <div className="right-btn" />;
          }}
        />
      </div>
    </div>
  );
}

function GalleryItemView(props: {
  gallery: Gallery;
  item: GalleryItem;
  openImage: (img: string) => void;
}) {
  let { item, openImage } = props;
  return (
    <div className="gallery-item" onClick={() => openImage(item.image)}>
      <img src={item.thumb} alt="" />
    </div>
  );
}
